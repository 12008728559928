<template>
  <div class="app-box">
    <div class="app-box-content">
      <div class="top-img">
        <img src="../assets/app/top.png" alt="" />
      </div>
      <div class="logo-img">
        <img src="../assets/app/logo.png" alt="" />
      </div>
      <p class="p1">全球领先的无人车配送服务平台</p>
      <a :href="linkHref" class="btn-img" @click="downloadClick">
        <img src="../assets/app/btn.png" alt="" />
      </a>
      <p class="p1 p3" @click="downloadClick">
        <a :href="linkHref">>> 已安装，点此打开 <<</a>
      </p>
      <div class="banner-img">
        <img src="../assets/app/banner.png" alt="" />
      </div>
      <div class="bottom-img">
        <img src="../assets/app/bottom.png" alt="" />
      </div>
      <div class="modal" v-if="showModal" @click="hideModal">
        <img src="../assets/app/tip.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { buriedPoint } from '@/api/api';
import { setTimeout } from 'timers';
export default {
  name: 'DownloadApp',
  components: {},
  props: {},
  data() {
    return {
      linkHref: '#',
      showModal: false,
      weixinFlage: false,
      error: '',
    };
  },
  computed: {},
  created() {
    // this.getModalSource()
    buriedPoint({
      eventInfos: [
        {
          eventVariableName: 'browser',
          eventAttribute: this.weixinFlage ? '微信浏览器' : '普通浏览器',
        },
      ],
      eventNo: '32', //见事件编号列
      eventName: this.$route.query.type, //见事件名称列
      parkCode: '', //为空可不传
      source: 0, //0:微信。1：支付宝
      token: sessionStorage.getItem('_token'), //必传
      vin: '', //为空可不传
    });
  },
  activated() {},
  mounted: function () {},
  destroyed() {},
  methods: {
    hideModal() {
      this.showModal = false;
    },
    getModalSource() {
      let isAndroid = false,
        isIOS = false,
        isIOS9 = false,
        version,
        u = navigator.userAgent,
        ua = u.toLowerCase();

      // 是否在微信打开
      if (
        /micromessenger/i.test(u.toLowerCase()) ||
        typeof navigator.wxuserAgent !== 'undefined'
      ) {
        // this.showModal = true
        this.weixinFlage = true;
        return;
      }

      //Android系统
      if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
        //android终端或者uc浏览器
        isAndroid = true;
        var limit_num = 100;
        var openTime = +new Date();
        window.location.href = 'neolix://main';
        var timer = setInterval(function () {
          if (limit_num > 0) {
            limit_num--;
          } else {
            if (new Date() - openTime < 2200) {
              //加了200ms基准误差
              window.location.href =
                'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AppVersion/CK/NeolixApp.apk';
            }
            clearTimeout(timer);
          }
        }, 20);
      }
      //ios
      if (ua.indexOf('like mac os x') > 0) {
        let regStr_saf = /os [\d._]*/gi;
        let verinfo = ua.match(regStr_saf);
        version = (verinfo + '').replace(/[^0-9|_.]/gi, '').replace(/_/gi, '.');
      }
      let version_str = version + '';
      // ios9以上
      if (version_str !== 'undefined' && version_str.length > 0) {
        version = parseInt(version);
        if (version >= 8) {
          isIOS9 = true;
          var limit_num = 100;
          var openTime = +new Date();
          window.location.href = 'neolix://main';
          var timer = setInterval(function () {
            if (limit_num > 0) {
              limit_num--;
            } else {
              if (new Date() - openTime < 2200) {
                //加了200ms基准误差
                window.location.href =
                  'https://apps.apple.com/cn/app/1607432751';
              }
              clearTimeout(timer);
            }
          }, 20);
        } else {
          isIOS = true;
          var limit_num = 100;
          var openTime = +new Date();
          window.location.href = 'neolix://main';
          var timer = setInterval(function () {
            if (limit_num > 0) {
              limit_num--;
            } else {
              if (new Date() - openTime < 2200) {
                //加了200ms基准误差
                window.location.href =
                  'https://apps.apple.com/cn/app/1607432751';
              }
              clearTimeout(timer);
            }
          }, 20);
        }
      }
    },
    downloadClick() {
      this.getModalSource();
      if (this.weixinFlage) {
        this.showModal = true;
      }
      buriedPoint({
        eventInfos: [
          {
            eventVariableName: 'browser',
            eventAttribute: this.weixinFlage ? '微信浏览器' : '普通浏览器',
          },
        ],
        deviceNo: null,
        eventNo: '31', //见事件编号列
        eventName: this.$route.query.type, //见事件名称列
        parkCode: '', //为空可不传
        source: 0, //0:微信。1：支付宝
        token: sessionStorage.getItem('_token'), //必传
        vin: '', //为空可不传
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.app-box {
  width: 100%;
  background: #fff;
  padding-bottom: 0.4rem;
  box-sizing: border-box;
  .app-box-content {
    height: 100%;
  }
  .top-img {
    width: 5.58rem;
    height: 2.86rem;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .logo-img {
    width: 3.56rem;
    height: 0.56rem;
    margin: 0 auto;
    margin-top: 0.23rem;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .p1 {
    font-size: 0.32rem;
    color: #a5a5a5;
    text-align: center;
    margin-top: 0.2rem;
  }
  .btn-img {
    width: 4.48rem;
    height: 1.36rem;
    margin: 0 auto;
    margin-top: 0.48rem;
    display: block;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .p3 {
    a {
      color: #592f10;
      text-decoration: none;
    }
  }
  .banner-img {
    width: 100%;
    height: 2rem;
    margin-top: 0.6rem;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .bottom-img {
    width: 6.62rem;
    height: 5.84rem;
    margin: 0 auto;
    margin-top: 0.72rem;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    img {
      width: 6rem;
      height: 2.16rem;
      display: block;
      float: right;
    }
  }
}
</style>
